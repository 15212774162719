import React from "react";
import { Link, useParams } from "react-router-dom";
import myData from "../db.json";

export default function Item() {
  let params = useParams();

  const cat = params.categorie;
  const sortedEntries = Object.entries(myData).filter((keyA) => {
    if (keyA[0] === cat) {
      return keyA[1]; // Sort ascending by key
    } else {
      // eslint-disable-next-line array-callback-return
      return;
    }
  });
  const item = Object.entries(sortedEntries[0][1])
    .filter((item) => {
      if (item[0] === params.afkomst) {
        return item[1];
      } else {
        // eslint-disable-next-line array-callback-return
        return;
      }
    })[0][1]
    .filter((item) => {
      if (item.naam === params.item) {
        return item;
      } else {
        // eslint-disable-next-line array-callback-return
        return;
      }
    })[0];

  return (
    <>
      <h1>
        {params.categorie} - {params.afkomst}
      </h1>
      <Link className="terug" to="/" style={{ position: "absolute", right: 0 }}>
        Terug naar begin
      </Link>
      <Link
        className="terug"
        to={`/${params.categorie}/${params.afkomst}`}
        style={{ position: "absolute", right: 0, top: "80px" }}
      >
        Terug naar {params.afkomst}
      </Link>
      <Link className="detail-item" to={item.link}>
        <strong>{item.naam}</strong>
        <br />
        {params.categorie === "Goden" ? (
          <p>God van: {item.god_van}</p>
        ) : params.categorie === "Dienaren" ? (
          <p>Dienaar van: {item.afkomst}</p>
        ) : (
          <p>Afkomst: {item.afkomst}</p>
        )}{" "}
        <br />
        Klik voor meer informatie!
      </Link>
    </>
  );
}
