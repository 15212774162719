import React from "react";
// import { Link, Route, Routes } from "react-router-dom";

import { Link, useParams } from "react-router-dom";
import myData from "../db.json";

function Categorie(props) {
  let params = useParams();
  // const cat = params.categorie;
  const sortedEntries = Object.entries(myData).filter((keyA) => {
    if (keyA[0] === params.categorie) {
      return keyA[1]; // Sort ascending by key
    }
    else{
      // eslint-disable-next-line array-callback-return
      return 
    }
  });
  const afkomst = Object.keys(sortedEntries[0][1]).sort(([keyA], [keyB]) => {
    return keyA.localeCompare(keyB); // Sort ascending by key
  });

  return (
    <>
      <h1>Kies een categorie / herkomst:</h1>
      <Link
        className="terug"
        style={{
          position: "absolute",
          right: 0,
        }}
        to="/"
      >
        Terug naar begin
      </Link>
      <div className="home-wrapper">
        {afkomst.map((culture, index) => (
          <Link key={index} to={culture} className="item-holder">
            {culture}
          </Link>
        ))}
      </div>
      {params.categorie === "Mensen" && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            margin: "auto",
            justifyContent: "center",
          }}
        >
          <div
            className="item-holder"
            style={{ maxWidth: "20%", alignSelf: "center" }}
          >
            <strong>Gezegende:</strong> Dit zijn mensen die een gift van een god
            hebben ontvangen.
          </div>
          <div
            className="item-holder"
            style={{ maxWidth: "20%", alignSelf: "center" }}
          >
            <strong>Halfgod:</strong> Dit zijn normale mensen zonder enige
            opmerkelijke bovennatuurlijke eigenschap.
          </div>
          <div
            className="item-holder"
            style={{ maxWidth: "20%", alignSelf: "center" }}
          >
            <strong>Sterveling:</strong> Dit zijn mensen die een god als vader
            of moeder hebben.
          </div>
        </div>
      )}
      {params.categorie === "Wezens" && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            margin: "auto",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          <div
            className="item-holder"
            style={{ maxWidth: "20%", alignSelf: "center" }}
          >
            <strong>Menselijk:</strong> Dit zijn wezens die lijken op mensen of
            een vergelijkbare lichaamsbouw hebben.
          </div>
          <div
            className="item-holder"
            style={{ maxWidth: "20%", alignSelf: "center" }}
          >
            <strong>Onmenselijk:</strong> Dit zijn wezens die niet op mensen
            lijken en niet een vergelijkbare lichaamsbouw hebben.
          </div>
          <div
            className="item-holder"
            style={{ maxWidth: "20%", alignSelf: "center" }}
          >
            <strong>Ondood:</strong> Dit zijn wezens die zijn opgestaan uit de
            dood en eigenlijk niet horen te leven.
          </div>
        </div>
      )}
    </>
  );
}

export default Categorie;
