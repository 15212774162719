import React from "react";
import { Link, useParams } from "react-router-dom";
import myData from "../db.json";

export default function Afkomst() {
  let params = useParams();
  const cat = params.categorie;
  const sortedEntries = Object.entries(myData).filter((keyA) => {
    if (keyA[0] === cat) {
      return keyA[1]; // Sort ascending by key
    }
    else{
      // eslint-disable-next-line array-callback-return
      return 
    }
  });
  const items = Object.entries(sortedEntries[0][1])
    .filter((item) => {
      if (item[0] === params.afkomst) {
        return item[1];
      }
      else{
        // eslint-disable-next-line array-callback-return
        return 
      }
    })[0][1]
    .sort((keyA, keyB) => {
      return keyA.naam.localeCompare(keyB.naam); // Sort ascending by key
    });

  return (
    <>
      <h1>
        Kies een van de {params.categorie}:
      </h1>
      <Link className="terug" to="/" style={{ position: "absolute", right: 0 }}>
        Terug naar begin
      </Link>
      <Link
        className="terug"
        to={`/${params.categorie}`}
        style={{ position: "absolute", right: 0, top: "80px" }}
      >
        Terug naar {params.categorie}
      </Link>
      <div className="home-wrapper">
        {items.map((info, index) => (
          <Link key={index} to={info.naam} className="item-holder">
            {info.naam}
          </Link>
        ))}
      </div>
    </>
  );
}
