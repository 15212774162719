import { Link, Route, Routes } from "react-router-dom";
import "./App.css";
import NoPage from "./paginas/NoPage";

import myData from "./db.json";
import Afkomst from "./paginas/Afkomst";
import Categorie from "./paginas/Categorie";
import Item from "./paginas/Item";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={
            <>
              <h1>Kies een categorie:</h1>
              <div className="home-wrapper">
                {Object.keys(myData).map((categorie) => (
                  <Link
                    key={categorie}
                    id={categorie}
                    to={categorie}
                    className="item-holder"
                  >
                    {categorie}
                  </Link>
                ))}
              </div>
              <br></br>
              <br></br>
              <div className="item-holder" style={{ maxWidth: "40%", alignSelf: "center"}}>
                Op deze site kan je (Bijna) elk mythisch figuur ooit vinden!
              </div>
            </>
          }
        />
        <Route path="/:categorie" element={<Categorie />}></Route>
        <Route path="/:categorie/:afkomst" element={<Afkomst />}></Route>
        <Route path="/:categorie/:afkomst/:item" element={<Item />}></Route>

        <Route path="*" element={<NoPage />} />
      </Routes>
    </div>
  );
}

export default App;
